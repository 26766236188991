import React from 'react';
import { Container, Typography } from '@mui/material';
import Auth from '../components/Auth';

const AuthPage: React.FC = () => {
  return (
    <Container maxWidth="sm">
      <Typography
        variant="h4"
        component="h1"
        align="center"
        gutterBottom
        sx={{ mt: 4 }}
      >
        Lectio Divina
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        color="text.secondary"
        sx={{ mb: 4 }}
      >
        Sign in to access your personal meditation space
      </Typography>
      <Auth />
    </Container>
  );
};

export default AuthPage;
