import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, addDays, subDays } from 'date-fns';
import { supabase } from '../lib/supabase';
import { fetchPassage } from '../lib/esv';

interface Reading {
  date: string;
  old_testament: string;
  new_testament: string;
  psalm: string;
  gospel: string;
}

// Function to generate Psalm of the day
const generatePsalmForDate = (date: Date): string => {
  const dayOfMonth = date.getDate();
  // If the day is greater than 150 (max psalms), loop back
  const psalmNumber = ((dayOfMonth - 1) % 150) + 1;
  return `Psalm ${psalmNumber}`;
};

export default function DailyReading() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [passages, setPassages] = useState<Record<string, string>>({});

  useEffect(() => {
    const fetchReadings = async () => {
      setLoading(true);
      setError(null);
      try {
        console.log('Fetching readings for date:', format(selectedDate, 'yyyy-MM-dd'));
        const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        const { data, error: supabaseError } = await supabase
          .from('lectionary_readings')
          .select('*')
          .eq('date', formattedDate)
          .single();

        if (supabaseError) {
          console.error('Supabase error:', supabaseError);
          throw supabaseError;
        }

        console.log('Supabase response:', data);

        let readingData: Reading;
        if (data) {
          readingData = data;
        } else {
          console.log('No lectionary data found, using fallback readings');
          // Generate fallback readings when no data exists
          const psalm = generatePsalmForDate(selectedDate);
          readingData = {
            date: formattedDate,
            old_testament: 'Genesis 1:1-10',
            new_testament: 'John 1:1-14',
            psalm,
            gospel: 'Matthew 1:1-17'
          };
        }

        console.log('Reading data to fetch:', readingData);
        
        // Fetch ESV text for all passages
        const passagePromises = Object.entries(readingData)
          .filter(([key]) => key !== 'date')
          .map(async ([key, passage]) => {
            console.log(`Fetching passage for ${key}:`, passage);
            try {
              const text = await fetchPassage(passage);
              console.log(`Received text for ${key}:`, text.substring(0, 100) + '...');
              return [key, text];
            } catch (err) {
              console.error(`Error fetching ${key}:`, err);
              return [key, `Error loading passage: ${passage}`];
            }
          });

        const passageResults = await Promise.all(passagePromises);
        const passageTexts = Object.fromEntries(passageResults);
        console.log('Final passage texts:', passageTexts);
        setPassages(passageTexts);
      } catch (err) {
        console.error('Error in fetchReadings:', err);
        setError('Failed to load readings. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchReadings();
  }, [selectedDate]);

  const handlePreviousDay = () => setSelectedDate(subDays(selectedDate, 1));
  const handleNextDay = () => setSelectedDate(addDays(selectedDate, 1));

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-50 border-l-4 border-red-500 p-4">
          <p className="text-red-700">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col items-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Daily Readings</h1>
        
        <div className="flex items-center gap-4 mb-8">
          <button 
            onClick={handlePreviousDay} 
            className="nav-button"
            aria-label="Previous Day"
          >
            Previous Day
          </button>
          
          <DatePicker
            selected={selectedDate}
            onChange={(date: Date | null) => date && setSelectedDate(date)}
            dateFormat="MMMM d, yyyy"
            className="text-center"
          />
          
          <button 
            onClick={handleNextDay} 
            className="nav-button"
            aria-label="Next Day"
          >
            Next Day
          </button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
        </div>
      ) : (
        <div className="max-w-4xl mx-auto">
          <div className="reading-card">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">Old Testament</h2>
            <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: passages.old_testament || 'Loading...' }} />
          </div>

          <div className="reading-card">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">Psalm</h2>
            <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: passages.psalm || 'Loading...' }} />
          </div>

          <div className="reading-card">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">New Testament</h2>
            <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: passages.new_testament || 'Loading...' }} />
          </div>

          <div className="reading-card">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">Gospel</h2>
            <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: passages.gospel || 'Loading...' }} />
          </div>
        </div>
      )}
    </div>
  );
}
