import React from 'react';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
          Welcome to Lectio Divina
        </h1>
        <p className="text-xl text-gray-600 dark:text-gray-300">
          A sacred space for scripture reading and meditation
        </p>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        <Link
          to="/daily-reading"
          className="block p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
        >
          <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
            Daily Reading
          </h2>
          <p className="text-gray-600 dark:text-gray-300">
            Explore today's scripture readings from the lectionary, including
            passages from the Old Testament, New Testament, and Psalms.
          </p>
        </Link>

        <Link
          to="/lectio-divina"
          className="block p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
        >
          <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
            Lectio Divina
          </h2>
          <p className="text-gray-600 dark:text-gray-300">
            Practice the ancient art of divine reading through guided meditation
            and contemplation of scripture.
          </p>
        </Link>
      </div>

      <div className="mt-12 text-center">
        <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
          About Lectio Divina
        </h3>
        <p className="text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
          Lectio Divina is a traditional practice of scriptural reading,
          meditation, and prayer intended to promote communion with God and to
          increase the knowledge of God's Word. It includes four stages: Read
          (Lectio), Meditate (Meditatio), Pray (Oratio), and Contemplate
          (Contemplatio).
        </p>
      </div>
    </div>
  );
};

export default Home;
