import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Reading } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import { saveReflectionNote, getUserReflectionNotes, ReflectionNote } from '../lib/supabase';

interface Stage {
  name: string;
  description: string;
  duration: number;
}

const stages: Stage[] = [
  {
    name: 'Lectio (Read)',
    description: "Read the passage slowly and attentively. Let the words sink in.",
    duration: 5,
  },
  {
    name: 'Meditatio (Reflect)',
    description: "Reflect on the meaning of the text. What is God saying to you through this passage?",
    duration: 10,
  },
  {
    name: 'Oratio (Respond)',
    description: "Respond to God in prayer based on what you have read and meditated upon.",
    duration: 10,
  },
  {
    name: 'Contemplatio (Rest)',
    description: "Rest in God's presence, letting go of your own thoughts and simply being with God.",
    duration: 5,
  },
];

const LectioDivina = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [passage, setPassage] = useState<string>('');
  const [currentStage, setCurrentStage] = useState<number>(0);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedReading, setSelectedReading] = useState<Reading | null>(null);
  const { user } = useAuth();
  const [notes, setNotes] = useState<ReflectionNote[]>([]);
  const [currentNote, setCurrentNote] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [currentTag, setCurrentTag] = useState('');
  const [saving, setSaving] = useState(false);

  // Get the reading from URL parameters
  useEffect(() => {
    const citation = searchParams.get('citation');
    const content = searchParams.get('content');
    
    if (citation && content) {
      setSelectedReading({ citation } as Reading);
      setPassage(decodeURIComponent(content));
      setLoading(false);
    } else {
      setError('No passage selected');
    }
  }, [searchParams]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isActive && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((time) => time - 1);
      }, 1000);
    } else if (timeLeft === 0 && currentStage < stages.length - 1) {
      setCurrentStage((stage) => stage + 1);
      setTimeLeft(stages[currentStage + 1].duration * 60);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isActive, timeLeft, currentStage]);

  useEffect(() => {
    if (user) {
      // Load existing notes for this passage
      getUserReflectionNotes().then(({ data }) => {
        if (data) {
          setNotes(data.filter(note => note.citation === selectedReading?.citation));
        }
      });
    }
  }, [user, selectedReading]);

  const startMeditation = () => {
    setTimeLeft(stages[currentStage].duration * 60);
    setIsActive(true);
  };

  const pauseMeditation = () => {
    setIsActive(false);
  };

  const resetMeditation = () => {
    setIsActive(false);
    setCurrentStage(0);
    setTimeLeft(stages[0].duration * 60);
    setNotes([]);
  };

  const formatTime = (seconds: number): string => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const handleSaveNote = async () => {
    if (!user) {
      // Redirect to login or show login prompt
      navigate('/auth');
      return;
    }

    if (!currentNote.trim()) return;

    setSaving(true);
    try {
      const { data, error } = await saveReflectionNote({
        citation: selectedReading?.citation || '',
        content: currentNote,
        tags,
      });

      if (error) throw error;
      if (data) {
        setNotes(prev => [data, ...prev]);
        setCurrentNote('');
        setTags([]);
      }
    } catch (err) {
      console.error('Error saving note:', err);
    } finally {
      setSaving(false);
    }
  };

  const handleAddTag = () => {
    if (currentTag.trim() && !tags.includes(currentTag.trim())) {
      setTags(prev => [...prev, currentTag.trim()]);
      setCurrentTag('');
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setTags(prev => prev.filter(tag => tag !== tagToRemove));
  };

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900 p-4 rounded-lg">
        <p className="text-red-600 dark:text-red-200">{error}</p>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-12 text-gray-900">
          Lectio Divina - {selectedReading?.citation}
        </h1>

        {/* Progress indicator */}
        <div className="mb-12">
          <div className="flex justify-between items-center mb-4">
            {stages.map((stage, index) => (
              <div
                key={stage.name}
                className={`flex-1 relative ${
                  index < currentStage
                    ? 'text-blue-600'
                    : index === currentStage
                    ? 'text-blue-600'
                    : 'text-gray-400'
                }`}
              >
                <div className="text-center">
                  <span
                    className={`w-8 h-8 rounded-full flex items-center justify-center border-2 mx-auto mb-2
                      ${
                        index < currentStage
                          ? 'bg-blue-600 border-blue-600 text-white'
                          : index === currentStage
                          ? 'border-blue-600 text-blue-600'
                          : 'border-gray-300'
                      }`}
                  >
                    {index + 1}
                  </span>
                  <span className="text-sm font-medium">{stage.name}</span>
                </div>
                {index < stages.length - 1 && (
                  <div
                    className={`absolute top-4 left-1/2 w-full h-0.5 -translate-y-1/2 ${
                      index < currentStage ? 'bg-blue-600' : 'bg-gray-300'
                    }`}
                  />
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Content */}
        <div className="bg-white rounded-xl shadow-lg p-8 mb-8">
          <div className="prose prose-lg max-w-none">
            {loading ? (
              <div className="flex justify-center py-8">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
              </div>
            ) : error ? (
              <div className="text-red-600 text-center py-4">{error}</div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: passage }} />
            )}
          </div>
        </div>

        {/* Timer */}
        {isActive && (
          <div className="text-center mb-8">
            <div className="text-4xl font-bold text-blue-600 mb-4">
              {formatTime(timeLeft)}
            </div>
          </div>
        )}

        {/* Navigation */}
        <div className="flex justify-between items-center">
          <button
            onClick={pauseMeditation}
            disabled={!isActive}
            className={`nav-button ${
              !isActive ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            Pause
          </button>
          <button
            onClick={startMeditation}
            disabled={isActive}
            className={`nav-button ${
              isActive ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            Start
          </button>
          <button
            onClick={resetMeditation}
            className="nav-button"
          >
            Reset
          </button>
        </div>

        {/* Reflections */}
        <div className="bg-white rounded-xl shadow-lg p-8 mb-8">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Your Reflections</h2>

          {/* Tags input */}
          <div className="mb-4">
            <div className="flex flex-wrap gap-2 mb-2">
              {tags.map(tag => (
                <span
                  key={tag}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800"
                >
                  {tag}
                  <button
                    type="button"
                    className="ml-1 text-indigo-600 hover:text-indigo-500"
                    onClick={() => handleRemoveTag(tag)}
                  >
                    &times;
                  </button>
                </span>
              ))}
            </div>
            <div className="flex gap-2">
              <input
                type="text"
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleAddTag()}
                placeholder="Add tags..."
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
              <button
                type="button"
                onClick={handleAddTag}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Tag
              </button>
            </div>
          </div>

          {/* Note input */}
          <div className="mt-4">
            <textarea
              rows={4}
              value={currentNote}
              onChange={(e) => setCurrentNote(e.target.value)}
              placeholder="Write your reflection..."
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
            <div className="mt-3">
              <button
                type="button"
                onClick={handleSaveNote}
                disabled={saving || !currentNote.trim()}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
              >
                {saving ? 'Saving...' : 'Save Reflection'}
              </button>
            </div>
          </div>

          {/* Previous notes */}
          <div className="mt-6 space-y-4">
            {notes.map(note => (
              <div
                key={note.id}
                className="bg-gray-50 dark:bg-gray-700 p-4 rounded-md"
              >
                <div className="flex flex-wrap gap-2 mb-2">
                  {note.tags.map(tag => (
                    <span
                      key={tag}
                      className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                <p className="text-sm text-gray-900 dark:text-gray-100">
                  {note.content}
                </p>
                <div className="mt-2 text-xs text-gray-500">
                  {new Date(note.created_at).toLocaleDateString()}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LectioDivina;
