import { createClient } from '@supabase/supabase-js';
import { format } from 'date-fns';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

console.log('Initializing Supabase with:', {
  url: supabaseUrl,
  hasKey: !!supabaseAnonKey
});

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'pkce',
    storage: window.localStorage
  }
});

// Auth helper functions
export const signInWithEmail = async (email: string, password: string) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  return { data, error };
};

export const signUpWithEmail = async (email: string, password: string) => {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
  });
  return { data, error };
};

export const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  return { error };
};

export const getCurrentUser = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  return user;
};

// Types for reflection notes
export interface ReflectionNote {
  id: string;
  user_id: string;
  citation: string;
  content: string;
  tags: string[];
  created_at: string;
  updated_at: string;
}

// Database helper functions for reflection notes
export const saveReflectionNote = async (note: Omit<ReflectionNote, 'id' | 'user_id' | 'created_at' | 'updated_at'>) => {
  const user = await getCurrentUser();
  if (!user) throw new Error('User not authenticated');

  const { data, error } = await supabase
    .from('reflection_notes')
    .insert([
      {
        user_id: user.id,
        citation: note.citation,
        content: note.content,
        tags: note.tags,
      }
    ])
    .select()
    .single();

  return { data, error };
};

export const getUserReflectionNotes = async () => {
  const user = await getCurrentUser();
  if (!user) throw new Error('User not authenticated');

  const { data, error } = await supabase
    .from('reflection_notes')
    .select('*')
    .eq('user_id', user.id)
    .order('created_at', { ascending: false });

  return { data, error };
};

export const updateReflectionNote = async (id: string, updates: Partial<ReflectionNote>) => {
  const user = await getCurrentUser();
  if (!user) throw new Error('User not authenticated');

  const { data, error } = await supabase
    .from('reflection_notes')
    .update(updates)
    .eq('id', id)
    .eq('user_id', user.id)
    .select()
    .single();

  return { data, error };
};

export const deleteReflectionNote = async (id: string) => {
  const user = await getCurrentUser();
  if (!user) throw new Error('User not authenticated');

  const { error } = await supabase
    .from('reflection_notes')
    .delete()
    .eq('id', id)
    .eq('user_id', user.id);

  return { error };
};

export interface Reading {
  type: 'OT' | 'NT' | 'Psalm' | 'Gospel';
  citation: string;
}

export interface LectionaryReading {
  id: number;
  date: string;
  year: 'A' | 'B' | 'C';
  season: string;
  description: string;
  feast_name: string | null;
  readings: Reading[];
  created_at: string;
  updated_at: string;
}

// Get Psalm of the day based on the date
const getPsalmOfDay = (date: Date): string => {
  const dayOfMonth = date.getDate();
  // If the day is greater than 150 (max psalms), loop back
  const psalmNumber = ((dayOfMonth - 1) % 150) + 1;
  return `Psalm ${psalmNumber}`;
};

// Function to fetch just the lectionary data for a given date
export const fetchLectionaryData = async (date: Date): Promise<LectionaryReading | null> => {
  const formattedDate = format(date, 'yyyy-MM-dd');
  console.log('Fetching lectionary data for date:', formattedDate);
  
  try {
    console.log('Making Supabase query...');
    const { data, error } = await supabase
      .from('lectionary_readings')
      .select('*')
      .eq('date', formattedDate)
      .single();

    if (error?.code === 'PGRST116' || !data) { // No data found
      console.log('No lectionary data found, using default readings with Psalm of the day');
      return {
        id: 0,
        date: formattedDate,
        year: 'A',
        season: 'Ordinary Time',
        description: 'Daily Reading',
        feast_name: format(date, 'MMMM d, yyyy'),
        readings: [
          {
            type: 'NT',
            citation: 'Colossians 1:1-29'
          },
          {
            type: 'Psalm',
            citation: getPsalmOfDay(date)
          }
        ],
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };
    }

    if (error) {
      console.error('Error fetching lectionary data:', error);
      return null;
    }

    console.log('Raw data from Supabase:', data);

    // Ensure readings is an array
    if (data.readings && typeof data.readings === 'string') {
      console.log('Parsing readings from string:', data.readings);
      try {
        data.readings = JSON.parse(data.readings);
      } catch (e) {
        console.error('Error parsing readings:', e);
        data.readings = [];
      }
    }

    // If readings is not an array, make it an empty array
    if (!Array.isArray(data.readings)) {
      console.warn('Readings is not an array, setting to empty array');
      data.readings = [];
    }

    console.log('Processed lectionary data:', data);
    return data as LectionaryReading;
  } catch (error) {
    console.error('Error in fetchLectionaryData:', error);
    return null;
  }
};

// Function to fetch and process the readings for a given date
export const getDailyReadings = async (date: Date): Promise<Reading[]> => {
  try {
    const lectionaryData = await fetchLectionaryData(date);
    
    if (!lectionaryData) {
      console.log('No lectionary data available');
      return [];
    }

    if (!lectionaryData.readings) {
      console.log('No readings found in lectionary data');
      return [];
    }

    if (!Array.isArray(lectionaryData.readings)) {
      console.error('Readings is not an array:', lectionaryData.readings);
      return [];
    }

    return lectionaryData.readings;
  } catch (error) {
    console.error('Error in getDailyReadings:', error);
    return [];
  }
};

export const getHolyDayReadings = async (date: Date) => {
  const formattedDate = format(date, 'yyyy-MM-dd');
  const { data, error } = await supabase
    .from('holy_day_readings')
    .select('*')
    .eq('date', formattedDate)
    .single();

  return { data, error };
};

// Insert sample holy day readings
export const insertSampleHolyDayReadings = async () => {
  const readings = [
    {
      date: '2024-11-24',
      holy_day_name: 'Reign of Christ - Proper 29',
      season: 'Twenty-Seventh Sunday after Pentecost',
      readings: [
        { type: 'OT', citation: '2 Samuel 23:1-7' },
        { type: 'Psalm', citation: 'Psalm 132:1-12, (13-18)' },
        { type: 'OT', citation: 'Daniel 7:9-10, 13-14' },
        { type: 'Psalm', citation: 'Psalm 93' },
        { type: 'NT', citation: 'Revelation 1:4b-8' },
        { type: 'Gospel', citation: 'John 18:33-37' }
      ]
    },
    {
      date: '2024-11-28',
      holy_day_name: 'Thanksgiving Day',
      readings: [
        { type: 'OT', citation: 'Joel 2:21-27' },
        { type: 'Psalm', citation: 'Psalm 126' },
        { type: 'NT', citation: '1 Timothy 2:1-7' },
        { type: 'Gospel', citation: 'Matthew 6:25-33' }
      ]
    }
  ];

  const { data, error } = await supabase
    .from('holy_day_readings')
    .insert(readings);

  return { data, error };
};
