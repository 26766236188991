import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      <nav className="bg-white dark:bg-gray-800 shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <Link
                to="/"
                className="flex items-center px-2 py-2 text-gray-900 dark:text-white hover:text-gray-600 dark:hover:text-gray-300"
              >
                Lectio Divina
              </Link>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                <Link
                  to="/daily-reading"
                  className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Daily Reading
                </Link>
                <Link
                  to="/lectio-divina"
                  className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Lectio Divina
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <main className="flex-grow py-10">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {children}
        </div>
      </main>

      <footer className="bg-white dark:bg-gray-800 shadow-lg mt-auto">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
          <div className="text-center text-sm text-gray-500 dark:text-gray-400">
            <Link to="/copyright" className="hover:text-gray-700 dark:hover:text-gray-300">
              ESV Bible Copyright Information
            </Link>
            <span className="mx-2">•</span>
            <span> 2024 Lectio Divina. All rights reserved.</span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
