const ESV_API_TOKEN = import.meta.env.VITE_ESV_API_TOKEN;
const ESV_API_URL = 'https://api.esv.org/v3/passage/html/';

if (!ESV_API_TOKEN) {
  throw new Error('Missing ESV API token in environment variables');
}

interface ESVPassageParams {
  q: string;
  include_headings?: boolean;
  include_footnotes?: boolean;
  include_verse_numbers?: boolean;
  include_short_copyright?: boolean;
  include_passage_references?: boolean;
  include_css?: boolean;
  wrapping_div?: boolean;
}

interface ESVPassageResponse {
  query: string;
  canonical: string;
  passages: string[];
}

export const fetchPassage = async (citation: string): Promise<string> => {
  console.log('Fetching ESV passage:', citation);
  
  const params: ESVPassageParams = {
    q: citation,
    include_headings: true,
    include_footnotes: false,
    include_verse_numbers: true,
    include_short_copyright: true,
    include_passage_references: true,
    include_css: false,
    wrapping_div: true
  };

  const queryString = new URLSearchParams(params as any).toString();
  
  try {
    console.log('Making ESV API request:', `${ESV_API_URL}?${queryString}`);
    const response = await fetch(`${ESV_API_URL}?${queryString}`, {
      headers: {
        'Authorization': `Token ${ESV_API_TOKEN}`,
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('ESV API error response:', errorText);
      throw new Error(`ESV API error: ${response.statusText}`);
    }

    const data: ESVPassageResponse = await response.json();
    console.log('ESV API response:', data);
    
    if (!data.passages || data.passages.length === 0) {
      console.warn('No passages found for citation:', citation);
      return `<p class="text-red-600">Passage not found: ${citation}</p>`;
    }

    // Clean up the HTML to work better with React
    const passage = data.passages[0]
      .replace(/class="/g, 'className="')
      .trim();

    return passage;
  } catch (error) {
    console.error('Error fetching ESV passage:', error);
    return `<p class="text-red-600">Error loading passage: ${citation}</p>`;
  }
}
