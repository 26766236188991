import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Layout from './components/Layout';
import Home from './pages/Home';
import DailyReading from './pages/DailyReading';
import LectioDivina from './pages/LectioDivina';
import Copyright from './pages/Copyright';
import Admin from './pages/Admin';
import AuthPage from './pages/AuthPage';
import AuthCallback from './pages/AuthCallback';

// Protected route wrapper
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/auth" />;
  }

  return <>{children}</>;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/daily-reading" element={<DailyReading />} />
            <Route path="/copyright" element={<Copyright />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route path="/admin" element={<Admin />} />
            <Route
              path="/lectio-divina"
              element={
                <ProtectedRoute>
                  <LectioDivina />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  );
};

export default App;
