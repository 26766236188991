const Copyright = () => {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-8 text-center">Copyright Information</h1>
      
      <div className="prose dark:prose-invert max-w-none">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-8">
          <h2 className="text-xl font-semibold mb-4">ESV Bible Copyright Notice</h2>
          <p className="mb-6">
            Scripture quotations are from the ESV® Bible (The Holy Bible, English Standard Version®),
            © 2001 by Crossway, a publishing ministry of Good News Publishers. Used by permission.
            All rights reserved. The ESV text may not be quoted in any publication made available
            to the public by a Creative Commons license. The ESV may not be translated into any
            other language.
          </p>
          
          <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
            <h3 className="text-lg font-medium mb-2">Usage Restrictions</h3>
            <p>
              Users may not copy or download more than 500 verses of the ESV Bible or more than
              one half of any book of the ESV Bible.
            </p>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h2 className="text-xl font-semibold mb-4">About Our Usage</h2>
          <p className="mb-4">
            The Lectio Divina app uses the ESV API to provide Scripture passages for meditation
            and daily reading. We comply with all ESV copyright requirements and usage restrictions.
          </p>
          <p>
            For more information about the ESV Bible and its usage terms, please visit{' '}
            <a
              href="https://www.esv.org/copyright/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 dark:text-blue-400 hover:underline"
            >
              ESV.org
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Copyright;
